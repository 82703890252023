.is--concept {
  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-breadcrumbs {
    display: none;
  }
}

.concept-main {
  max-width: 100%;
  width: 1090px;
  margin: 53px auto 36px;
  padding: 0 ;
  font-family: $font-family-serif;
  @include media-up(lg) {
    margin: 104px auto 36px;
  }
}

.concept-title {
  margin-bottom: 60px;
  @include media-up(lg) {
    margin-bottom: 75px;
  }

  .is-title {
    @include c-block-title;
    margin-bottom: 25px;
    font-size: calc(20 / 16 * $font-size-base);
  }

  .is-catch {
    margin-right: -.2em;
    font-size: min(calc(24 / 16 * $font-size-base), 6.4vw);
    font-weight: $font-weight-normal;
    line-height: 1.85;
    letter-spacing: .2em;
    @include media-up(lg) {
      font-size: calc(28 / 16 * $font-size-base);
    }
  }
}

.concept-body {
  margin-bottom: 54px;
  padding-left: min(36px, 9.6%);
  padding-right: min(20px, 5.333333%);
  @include media-up(lg) {
    margin-bottom: 36px;
    padding-left: 34px;
    padding-right: 34px;
  }

  &__section + &__section {
    margin-top: 80px;
  }

  &__title {
    margin-bottom: 45px;
    font-size: min($font-size-lg, 4.8vw);
    line-height: 1.8;
    @include media-up(lg) {
      font-size: calc(20 / 16 * $font-size-base);
    }
  }

  &__text {
    white-space: nowrap;
    font-size: min($font-size-sm, 3.733333333vw);
    line-height: 2.5;
    letter-spacing: .02em;
    @include media-up(lg) {
      font-size: $font-size-base;
      letter-spacing: .05em;
    }
  }

  &__text + &__text {
    margin-top: 45px;
    @include media-up(lg) {
      margin-top: 42px;
    }
  }

  &__profile {
    margin-top: 42px;
    @include media-up(lg) {
      margin-top: 47px;
    }
    .is-title {
      display: block;
      margin-bottom: 1rem;
      font-size: $font-size-sm;
    }
    .is-name {
      display: block;
      font-weight: $font-weight-normal;
      font-size: calc(20 / 16 * $font-size-base);
      letter-spacing: .2em;
    }

    .btnwrap {
      margin-top: 40px;
      @include media-up(lg) {
        margin-top: 44px;
      }
    }
  }
}

.concept-movie {
  margin: 0 auto;
  padding: 0 min(36px, 9.6%);
  pointer-events: none;
  @media (min-width: 1050px) {
    position: fixed;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 0;
    z-index: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 1050px) {
      position: absolute;
      bottom: 50%;
      right: 0;
      align-items: flex-end;
      width: 100%;
      height: 100vh;
      height: 100svh;
    }
  }
  &__item {
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: $transition-base;
    }
  }
  .is-write {
    aspect-ratio: 216/290;
    width: 57.6vw;
    height: auto;
    margin-left: 21.33333333vw;
    @include media-up(lg) {
      width: 216px;
      height: 290px;
      margin-left: 80px;
    }
    video {
      aspect-ratio: 216/290;
      background: url('/assets/img/concept/thumb_sekkei@2x.jpg') 50%/cover no-repeat;
    }
  }
  
  .is-forest {
    aspect-ratio: 170/170;
    width: 45.33333333vw;
    margin-top: -32px;
    margin-right: 33.6vw;
    @include media-up(lg) {
      width: 170px;
      height: 170px;
      margin-right: 126px;
    }
    video {
      aspect-ratio: 170/170;
      background: url('/assets/img/concept/thumb_sekkei@2x.jpg') 50%/cover no-repeat;
    }
  }
}

.concept-outline {
  position: relative;
  z-index: 0;
  @include media-up(lg) {
    display: flex;
    flex-direction: column-reverse;
  }
  @include media-down(lg) {
    background-color: #ECEFF1;
  }

  &:before {
    @include media-up(lg) {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100% - 150px);
      background-color: #ECEFF1;
      z-index: -1;
    }
  }

  &__inner {
    max-width: 100%;
    width: calc(960px + 68px);
    margin: 0 auto;
    padding: 78px min(20px, 5.333333%) 58px min(36px, 9.6%);
    @include media-up(lg) {
      margin-top: -2em;
      padding: 0 34px 100px;
    }
  }

  &__title {
    @include c-block-title();
    margin-bottom: 44px;
    font-weight: $font-weight-normal;
    @include media-up(lg) {
      margin-bottom: 68px;
    }
  }

  &__img {
    margin: 0;
    text-align: right;
    img {
      max-width: 100%;
      height: auto;
      @include media-down(lg) {
        width: 100%;
      }
    }
  }

  &__table {
    font-family: $font-family-serif;
    th, td {
      padding: 0.5em 0;
      vertical-align: top;
      font-weight: $font-weight-normal;
      font-size: min(calc(13 / 16 * $font-size-base), 3.466666667vw);
      line-height: 2.3;
      letter-spacing: .1em;
      @include media-up(lg) {
        font-size: calc(15 / 16 * $font-size-base);
      }
    }
    th {
      white-space: nowrap;
      padding-right: 1em;
    }
  }
}
