// .rwd002-pagination {
//   margin: 4em auto;
//   font-feature-settings: "palt";
//   &__totalpages {
//     padding-left: map-get($spacers, 3);
//     padding-right: map-get($spacers, 3);
//     margin-bottom: 1.5em;
//     font-size: 0.8125rem;
//     font-weight: $font-weight-bold;
//     letter-spacing: 0.3em;
//     text-indent: 0.3em;
//     text-align: center;
//   }
//   &__pageNav {
//     width: auto;
//     margin: 0 auto;
//     border: 1px solid $dark;
//     border-width: 1px 0;
//     display: flex;
//     justify-content: center;
//   }
//   .is-prev,
//   .is-next {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     max-width: 480px;
//     font-weight: $font-weight-normal;
//     color: $dark;
//     font-size: 1rem;
//     line-height: 1em;
//     font-family: "Roboto", $font-family-sans-serif;
//     letter-spacing: 0.2em;
//     text-indent: 0.2em;
//     background: transparent;
//     border: 0px solid $body-color;
//     transition: $transition-base;
//     @include media-up(sm) {
//       height: 100px;
//     }
//     @include media-down(sm) {
//       font-size: 0.875rem;
//       height: 44px;
//     }
//     > span {
//       order: 1;
//       flex-grow: 1;
//       display: block;
//       padding: 1em;
//       font-size: 1rem;
//       letter-spacing: 0.1em;
//       text-indent: 0;
//       text-align: left;
//       font-weight: $font-weight-normal;
//     }
//     .thumb {
//       max-height: 85px;
//       max-width: 120px;
//       overflow: hidden;
//       @include media-down(sm) {
//         display: none;
//       }
//       img {
//         display: block;
//         width: 100%;
//         object-fit: cover;
//       }
//     }
//   }
//   .is-prev > .thumb {
//     order: 0;
//   }
//   .is-next > .thumb {
//     order: 1;
//   }
//   .is-prev:before,
//   .is-next:after {
//     content: "";
//     display: block;
//     width: 20px;
//     height: 40px;
//     background: center / contain no-repeat;
//     flex-shrink: 0;
//     @include media-down(sm) {
//       width: 13px;
//       height: 25px;
//     }
//   }
//   .is-prev:before {
//     margin-right: 1.5em;
//     background-image: svgUrlFunc(
//       '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 21 41"><polyline points="20.5,0.5 0.5,20.5 20.5,40.5" stroke="#{$dark}" fill="none" stroke-miterlimit="10"/></svg>'
//     );
//     order: 0;
//     @include media-down(sm) {
//       margin-right: 0.6em;
//     }
//   }
//   .is-next:after {
//     margin-left: 1.5em;
//     background-image: svgUrlFunc(
//       '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 21 41"><polyline points="0.5,0.5 20.5,20.5 0.5,40.5" stroke="#{$dark}" fill="none" stroke-miterlimit="10"/></svg>'
//     );
//     order: 2;
//     @include media-down(sm) {
//       margin-left: 0.6em;
//     }
//   }

//   .is-prev:not(.disable):before,
//   .is-next:not(.disable):after {
//     color: $dark;
//   }
//   .is-prev.disable,
//   .is-next.disable {
//     color: tint($dark, 20%);
//     background: transparent;
//     border: 0px solid $light;
//     opacity: 0.7;
//     pointer-events: none;
//   }
//   .is-prev:hover:not(.disable),
//   .is-next:hover:not(.disable) {
//     text-decoration: none;
//     color: $white;
//     background-color: $dark;
//     &:before,
//     &:after {
//       color: $white;
//     }
//   }
//   .is-prev:hover:not(.disable):before {
//     background-image: svgUrlFunc(
//       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 41"><polyline points="20.5,0.5 0.5,20.5 20.5,40.5" stroke="#{$white}" fill="none" stroke-miterlimit="10"/></svg>'
//     );
//   }
//   .is-next:hover:not(.disable):after {
//     background-image: svgUrlFunc(
//       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 41"><polyline points="0.5,0.5 20.5,20.5 0.5,40.5" stroke="#{$white}" fill="none" stroke-miterlimit="10"/></svg>'
//     );
//   }

//   &__pages {
//     padding: 0;
//     margin: 0;
//     display: flex;
//     justify-content: center;
//     list-style: none;
//   }

//   &__pages li {
//     position: relative;
//     font-size: 1rem;
//     margin: 0;
//     letter-spacing: 0.2em;
//     text-indent: 0.2em;
//     font-weight: $font-weight-normal;
//     font-family: "Roboto", $font-family-sans-serif;

//     span,
//     a,
//     i {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 6.25em;
//       height: 6.25em;
//       transition: $transition-base;
//       @include media-down(sm) {
//         width: 2.8em;
//         height: 2.8em;
//       }
//     }
//     span {
//       color: $white;
//       background: $dark;
//     }
//     a {
//       transition: $transition-base;
//       color: $dark;
//       background: transparent;
//       &:hover {
//         text-decoration: none;
//         color: $white;
//         background: $dark;
//       }
//     }
//     i {
//       color: $dark;
//       letter-spacing: 0.02em;
//       text-indent: 0.02em;
//       padding-bottom: 0.5em;
//     }
//     &:after {
//       left: 50%;
//       position: absolute;
//       bottom: 0;
//     }
//     &:hover:after,
//     &[class*="current"]:after,
//     &[class*="active"]:after {
//       display: none;
//     }
//   }

//   .is-backbtn {
//     flex-grow: 1;
//     padding: 0px;
//     text-align: center;
//     @include media-up(md) {
//       width: 100%;
//       max-width: 126px;
//       margin: 0;
//     }
//     @include media-down(sm) {
//       max-width: 80px;
//     }
//   }
//   .is-backbtn a {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     white-space: nowrap;
//     width: 100%;
//     height: 100%;
//     padding: 24px 15px;
//     font-weight: 700;
//     letter-spacing: 0.3em;
//     text-indent: 0.3em;
//     transition: $transition-base;
//     color: $dark;
//     background: transparent;
//     border: solid $dark;
//     border-width: 0px 1px;
//     font-size: 0;
//     color: transparent;
//     text-indent: -9999px;
//     @include media-up(sm) {
//       height: 100px;
//     }
//     @include media-down(sm) {
//       padding: 12px;
//     }
//     &:after {
//       content: "";
//       display: block;
//       width: 30px;
//       height: 30px;
//       margin: 0 auto;
//       background: svgUrlFunc(
//           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.27 27.6"><defs><style>.c{fill:#{$light};}</style></defs><g><circle class="c" cx="2.67" cy="2.67" r="2.67"/><circle class="c" cx="15.14" cy="2.67" r="2.67"/><circle class="c" cx="27.6" cy="2.67" r="2.67"/><circle class="c" cx="2.67" cy="13.8" r="2.67"/><circle class="c" cx="15.14" cy="13.8" r="2.67"/><circle class="c" cx="27.6" cy="13.8" r="2.67"/><circle class="c" cx="2.67" cy="24.93" r="2.67"/><circle class="c" cx="15.14" cy="24.93" r="2.67"/><circle class="c" cx="27.6" cy="24.93" r="2.67"/></g></svg>'
//         )
//         center / contain no-repeat;
//       @include media-down(sm) {
//         width: 20px;
//         height: 20px;
//       }
//     }
//     &:hover {
//       text-decoration: none;
//       background: $dark;
//       color: $white;
//     }
//   }
// }

.rwd002-pagination {
  padding: 0 map-get($spacers, 3);
  margin: map-get($spacers, 6) 0;

  .is-prev,
  .is-next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0.5em;
    &.disable{
      opacity: 0.4;
      pointer-events: none;
    }
    .thumb{
      display: block;
      width: min(15vw, 120px);
      height: min(10vw, 80px);
      object-fit: cover;
      margin: 0 1em;
    }
    .subject {
      font-size: calc(13 / 16 * $font-size-base);
      font-family: $font-family-serif;
      letter-spacing: 0.1em;
      line-height: 1.8;
      margin: 0 1em;
      max-width: 14em;
      @include media-down(lg) {
        display: none;
      }
    }
  }
  
  .is-prev:before,
  .is-next:after {
    display: block;
    content: '';
    width: min(46px, 12vw);
    height: 10px;
    background: center / contain no-repeat;
    transition: $transition-base;
  }
  .is-prev:before {
    background-image: svgUrlFunc('<svg viewBox="0 0 46 10" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m1.07 4.53h44.93v.93h-44.93z"/><path d="m5.35 10-5.35-5 5.35-5 .7.66-4.64 4.34 4.64 4.34z"/></g></svg>');
  }
  .is-next:after {
    background-image: svgUrlFunc('<svg viewBox="0 0 46 10" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m0 4.53h44.93v.93h-44.93z"/><path d="m40.65 10-.7-.66 4.64-4.34-4.64-4.34.7-.66 5.35 5z"/></g></svg>');
  }
  .is-prev:hover:before{
    transform: translateX(-10%);
  }
  .is-next:hover:after{
    transform: translateX(10%);
  }

  // 一覧ページング
  &__pageNav{
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .is-prev span,
    .is-next span{
      display: none;
    }
  }

  &__pages {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    align-items: center;
    margin-right: auto;
    color: tint($body-color, 50%);
    font-size: min( 3.46vw, $font-size-base);

    li {
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }

    li a, li span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: min(42px, 9vw );
      height: min(42px, 9vw);
      border: 1px solid;
      transition: $transition-base;
      line-height: 1;
    }

    li span {
      background-color: $body-color;
      border-color: $body-color;
    }

    li a:hover{
      text-decoration: none;
      background-color: tint($body-color, 90%);
    }
  }

  // 詳細ページング
  &__detailNav {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 calc( -1 * map-get($spacers, 3));
    border: solid #CDCDCD;
    border-width: 1px 0;

    .is-backbtn a{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: min(15vw, 100px);
      span{
        display: none;
      }
      &:after {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        margin: 0 auto;
        background: svgUrlFunc(
            '<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><g fill="#{$gray-500}"><path d="m0 0h5v5h-5z"/><path d="m10 0h5v5h-5z"/><path d="m20 0h5v5h-5z"/><path d="m0 10h5v5h-5z"/><path d="m10 10h5v5h-5z"/><path d="m20 10h5v5h-5z"/><path d="m0 20h5v5h-5z"/><path d="m10 20h5v5h-5z"/><path d="m20 20h5v5h-5z"/></g></svg>'
          )
          center / contain no-repeat;
        @include media-down(sm) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .is-next,
    .is-prev {
      width: min(500px, 42.5vw );
      @include media-up(lg) {
        height: 180px;
      }
      padding-left: 1em;
      padding-right: 1em;
    }
    .is-prev {
      justify-content: flex-start;
    }
    .is-next {
      justify-content: flex-end;
    }
  }
}