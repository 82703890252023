@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Cormorant Garamond', $font-family-serif;
  font-size: calc(22 / 16 * $font-size-base);
  font-weight: normal;
  letter-spacing: .15em;
  text-indent: 0.15em;
  @include media-up(lg) {
    font-size: calc(28 / 16 * $font-size-base);
  }
}

@mixin c-block-title($theme: $body-color) {
  font-family: 'Cormorant Garamond', $font-family-serif;
  font-weight: $font-weight-medium;
  font-size: calc(28 / 16 * $font-size-base);
  letter-spacing: .15em;
}
