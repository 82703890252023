html.is-active-splash{
  body{
    height: 100%;
    overflow: hidden;
  }
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
  width: 100vw;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  background-color: #F8F8F8;
  z-index: -1;
  transform: translateY(-100%);
  .is-active-splash &{
    opacity: 1;
    z-index: 101;
    transform: translateY(0);
  }
  .is-fadeout-splash &{
    opacity: 0;
  }

  &__container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__bg {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 120vw;
    background-image: url('/assets/img/home/splash_bg@2x.jpg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    object-fit: cover;
    opacity: 0;
    transition:
      opacity 600ms ease-in-out,
      transform 4200ms cubic-bezier(0.37, 0, 0.63, 1);
    will-change: opacity, transform;
    
    @media (max-width: 899px) and ( orientation: portrait ) {
      height: calc(100vw + 100vh);
      background-image: url('/assets/img/home/splash_bg_sp@2x.jpg');
    }

    &.is-move {
      transform: translateY(calc(50vw - 160px));
      @media (max-width: 899px) and ( orientation: portrait ) {
        transform: translateY(calc(100vw));
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    width: 100%; height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: opacity 0.8s ease-in-out;
    z-index: 1;
    #mask_anim {
      position: relative;
      width: 80%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 20px;
      height: auto;
      margin: auto;
      @include media-up(lg) {
        width: 32px;
      }
    }
    .st1 {
      fill: none;
      stroke: $white;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 6px;
      stroke-miterlimit: 10;
      opacity: 1;
    }
  }
}