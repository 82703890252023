.rwd002-block-concept{
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  margin: map-get($spacers, 6) 0;

  .is-side &{
    margin: map-get($spacers, 5) 0;
  }
  @include media-down(lg) {
    margin: map-get($spacers, 4) 0;
  }
  
  @include media-down(md) {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);  
  }

  &__inner{
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  &__title{
    font-size: $h1-font-size;
    font-weight: $font-weight-bold;
    letter-spacing: 0.16em;
    font-feature-settings: "palt";
    margin-bottom: 0.6em;
    .is-side &{
      font-size: $font-size-base * 1.625;
    }
    @include media-down(lg) {
      font-size: $h3-font-size;
    }
    
  }

  &__body{
    @extend %text-entry-body !optional;
    line-height: $line-height-xl;
    .is-side &{
      line-height: $line-height-lg;
    }
    @include media-down(md) {
      line-height: $line-height-lg;
    }
  }
}
