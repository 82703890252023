.editor-template {
  font: inherit;
  font-family: $font-family-serif;
  font-size: inherit;
  color: inherit;
  letter-spacing: inherit;
  
  p{
    font-family: $font-family-serif;
    font-size: inherit;
    font: inherit;
    color: inherit;
  }
}