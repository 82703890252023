.is--profiles {
  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-breadcrumbs {
    display: none;
  }
  
  .content-head {
    max-width: 100%;
    width: 1000px;
    margin: 45px auto;
    padding: 0 20px;
    @include media-up(lg) {
      margin: 104px auto 0;
    }

    &__title {
      @include c-block-title();
      margin-bottom: 48px;
      @include media-up(lg) {
        margin-bottom: 70px;
      }
    }
  }
}

.profiles-head {
  margin-bottom: 75px;
  @include media-up(lg) {
    display: flex;
    margin-bottom: 130px;
  }

  &__img {
    margin: 0;
    @include media-up(lg) {
      flex-shrink: 0;
      width: 60%;
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      @include media-up(lg) {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__body {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 70px 20px 40px 40px;
    font-family: $font-family-serif;
    background-color: $gray-700;
    color: $white;
    @include media-up(lg) {
      flex-grow: 1;
      padding: 30px 30px min(100px, 7.142857143vw) min(100px, 7.142857143vw);
    }
  }

  &__name {
    margin: 0;
    .is-title {
      font-size: $font-size-sm;
      letter-spacing: .2em;
    }
    .is-name {
      display: block;
      margin-top: 28px;
      font-size: calc(20 / 16 * $font-size-base);
      letter-spacing: .5em;
      @include media-up(lg) {
      }
    }
    small {
      display: block;
      margin-top: 18px;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: .15em;
      @include media-up(lg) {
        margin-top: 15px;
      }
    }
  }

}

.profiles-content {
  max-width: 100%;
  width: 840px;
  margin: 0 auto 98px;
  padding: 0 36px;
  font-family: $font-family-serif;
  @include media-up(lg) {
    margin: 0 auto 160px;
    padding: 0 20px;
  }
  h3 {
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 15px;
    font-size: calc(19 / 16 * $font-size-base);
    letter-spacing: .05em;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 18px;
      height: 1px;
      background-color: $body-color;
    }
  }
  table {
    margin-bottom: 107px;
    @include media-up(lg) {
      margin-bottom: 90px;
    }
    th, td {
      padding: 0;
      vertical-align: top;
      font-weight: $font-weight-normal;
      font-size: min(calc(13 / 16 * $font-size-base), 3.466666667vw);
      line-height: 2.3;
      letter-spacing: .1em;
      @include media-up(lg) {
        padding: 0.25em 0;
        font-size: calc(15 / 16 * $font-size-base);
      }
      @include media-down(lg) {
        display: block;
      }
    }
    th {
      white-space: nowrap;
      padding-right: 1em;
    }

    th + td, td + td {
      @include media-down(lg) {
        padding-left: 27px;
      }
    }

    td {
      &:last-child {
        padding-bottom: 0.75em;
      }
      a {
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-thickness: 1px;
      }
    }

  }
}