.is--works {
  .rwd002-container {
    margin-top: 0;
  }

  &.is--list {
    .rwd002-pagination {
      @include media-up(lg) {
        width: 80%;
        margin-top: 95px;
        margin-bottom: 125px;
        margin-left: auto;
        padding: 0;
        padding-right: 100px;
      }

      &__pageNav {
        @include media-up(lg) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .rwd002-list-content {
      @include media-up(lg) {
        display: flex;
        margin-top: map-get($spacers, 2);
        margin-bottom: 125px;
        padding: 0;
      }
      @include media-down(lg) {
        padding-left: 0;
        padding-right: 0;
      }

      &__inner {
        @include media-up(lg) {
          max-width: 100%;
          width: 80%;
          margin: 0;
        }
      }

      &__list {
        @include make-row(0);
        --bs-gutter-y: 72px;
        
        @include media-down(lg) {
          --bs-gutter-y: 40px;
          flex-direction: column;
        }
      }
    }
  }

  &.is--detail {
    //detail
    .rwd002-detail {
      &__entry {
        max-width: 100%;
      }
      &__image {
        width: 100%;
        margin-bottom: 42px;
        @include media-up(lg) {
          padding-left: min(80px, 5.75vw);
          padding-right: min(80px, 5.75vw);
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      &__image-inner {
        width: 100%;
      }
    }
  }
}

.works-catelist {
  font-family: $font-family-serif;

  &__inner {
    display: inline-flex;
    flex-direction: column;
  }

  .cate {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: .2em 0 .2em 25px;
    font-size: calc(13 / 16 * $font-size-base);
    color: $gray-700;
    line-height: 1.8;
    letter-spacing: .1em;

    @include media-up(lg) {
      padding-left: 28px;
      font-size: $font-size-base;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: inline-block;
      width: 0;
      height: 1px;
      margin: auto;
      background-color: $gray-700;
      transition: $transition-base;
    }

    &:hover,
    &:focus,
    &.current {
      text-decoration: none;

      &:before {
        width: 15px;

        @include media-up(lg) {
          width: 18px;
        }
      }
    }
  }
}

//list
.works-list-head {
  padding-left: min(36px,9.6%);
  padding-right: min(36px,9.6%);
  @include media-up(lg) {
    width: 20%;
    padding-left: min(80px, 5.75vw);
    padding-right: 20px;
  }
  @include media-down(lg) {
    margin-bottom: 40px;
  }

  &__title {
    @include c-block-title();
    margin-bottom: 33px;

    @include media-up(lg) {
      margin-bottom: 44px;
    }
  }
}

.works-item {
  @include make-col-ready();
  @include make-col(12);
  font-family: $font-family-serif;

  @include media-up(lg) {
    @include make-col(6);
  }

  &:hover,
  &:focus {
    text-decoration: none;

    img {
      transform: scale(1.1);
    }
  }

  &__thumb {
    position: relative;
    aspect-ratio: 560/323;
    margin-bottom: 20px;
    overflow: hidden;
    z-index: 0;

    .cate {
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
      max-width: 80%;
      min-width: 91px;
      height: 30px;
      line-height: 30px;
      padding: 0 1em;
      background: $body-color;
      color: $white;
      font-size: $font-size-xxs;
      letter-spacing: .1em;
      text-indent: 0.1em;
      text-align: center;
      z-index: 1;

      @include media-up(lg) {
        font-size: calc(13 / 16 * $font-size-base);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: $transition-base;
    }
  }

  &__body {
    @include media-down(lg) {
      padding-left: min(36px,9.6%);
      padding-right: min(36px,9.6%);
    }
    .title {
      display: block;
      font-size: $font-size-base;
      letter-spacing: 0.05em;

      @include media-up(lg) {
        font-size: $font-size-lg;
      }
      @include media-down(lg) {
        margin-bottom: 0;
      }
    }
  }
}

//detail
.works-detail {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: $font-family-serif;

  &__inner {
    display: flex;

    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &-head {
    @include c-detail-head();
    margin-bottom: 44px;
    @include media-up(lg) {
      margin-bottom: 60px;
    }
  }

  &__body {
    width: 100%;
    padding: 0 30px 40px;
    font-size: $font-size-xs;
    @include media-up(lg) {
      width: 40%;
      padding: 0 min(80px, 5.75vw);
      font-size: calc(15 / 16 * $font-size-base);
      z-index: 0;
    }

    .editor-template {
      font-size: $font-size-xs !important;
      letter-spacing: .05em;
      @include media-up(lg) {
        font-size: calc(15 / 16 * $font-size-base) !important;
      }
    }

    p {
      margin: 0;
      font-size: $font-size-xs;
      line-height: 1.86;
      letter-spacing: .05em;

      @include media-up(lg) {
        font-size: calc(15 / 16 * $font-size-base);
      }
    }

    p + p {
      margin-top: 2rem;
    }

    table {
      max-width: 100%;
      width: 100%;
      margin-top: 34px;
      margin-left: auto;
      margin-right: auto;
      border: solid #CDCDCD;
      border-width: 1px 0 0;
      
      @include media-up(lg) {
        margin-top: 43px;
      }

      th,
      td {
        padding: .75em 1em;
        background: transparent;
        border: solid 1px #CDCDCD;
        border-width: 0 0 1px;
        font-family: $font-family-serif;
        font-weight: $font-weight-normal;
        font-size: $font-size-xs;
        letter-spacing: .05em;
        line-height: 2;

        @include media-up(lg) {
          font-size: calc(15 / 16 * $font-size-base);
        }

        p {
          margin-top: 0;
          margin-bottom: 0;
          letter-spacing: .05em;

          &+p {
            margin-top: 1em;
          }
        }
      }

      th {
        vertical-align: top;
        padding-left: 0;
        white-space: nowrap;
        @include media-up(md) {
          width: 100px;
        }
      }

    }
  }

  &__body-inner {
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      top: 30px;
      left: 0;
    }
  }

  &__img {
    width: 100%;
    @include media-up(lg) {
      width: 60%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}