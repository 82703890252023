@keyframes scroll_line {
  0% {
    height: 6px;
    opacity: 0;
  }
  30% {
    height: 6px;
    opacity: 1;
  }
  60% {
    height: 56px;
  }
  100% {
    height: 0;
  }
}

.rwd002-mainImg {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
  }
  &__inner {
    img {
      max-width: 100%;
      height: auto;
    }
    > img[width="100%"] {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      max-height: calc(100vh - 120px);
      @include media-down(md) {
        max-height: calc(100vh - 55px);
      }
    }
  }
}

.mainvisual {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 86px);
  height: calc(100svh - 86px);
  z-index: 0;
  @include media-up(lg) {
    height: calc(100vh - 150px - 100px);
    height: calc(100svh - 150px - 100px);
    margin-bottom: 100px;
  }
  &__logo {
    @include media-up(lg) {
      display: flex;
      justify-content: center;
      width: 20%;
      height: 100%;
      margin: 0;
      background-color: $white;
    }
    @include media-down(lg) {
      display: none;
    }
    img {
      @include media-up(lg) {
        margin-top: -63px;
      }
    }
  }

  &__bg {
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
    overflow: hidden;
    z-index: -1;
    .is-caption {
      position: absolute;
      right: 26px;
      bottom: 15px;
      font-size: $font-size-xxs;
      color: $white;
      letter-spacing: .1em;
      z-index: 1;
      @include media-down(lg) {
        right: 32px;
        bottom: 14px;
      }
    }
  }

  &__bg-img {
    position: absolute;
    top: -150px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    height: 100svh;
    background-color: #F8F8F8;
    z-index: 0;
    @include media-down(lg) {
      top: -86px;
    }
  }
  
  &__bg-img:after {
    content: '';
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 120vw;
    background: url('/assets/img/home/mainvisual@2x.jpg');
    background-size: cover;
    background-position: center calc(100% + calc(50vw - 160px));
    background-repeat: no-repeat;
    opacity: 0;
    
    @media (max-width: 899px) and ( orientation: portrait ) {
      height: calc(100vh + 100vw);
      height: calc(100svh + 100vw);
      background-position: center bottom;
      background-image: url('/assets/img/home/mainvisual_sp@2x.jpg');
      transform: translateY(calc(100vw));
    }

    .is-ready &{
      opacity: 1;
    }
  }

  &__scroll {
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    left: 2.35vw;
    bottom: -100px;
    height: 76px;
    color: $body-color;
    font-size: calc(13 / 16 * $font-size-base);
    @include media-down(lg) {
      display: none;
    }
    &:hover,
    &:focus {
      color: $body-color;
      text-decoration: none;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 20px;
      left: 50%;
      background-color: #939393;
      transform: translateX(-50%);
    }
    &:before {
      width: 1px;
      height: 56px;
    }
    &:after {
      width: 1px;
      height: 56px;
      background-color: $body-color;
      animation: scroll_line 3s ease-in-out infinite;
    }
    span {
      white-space: nowrap;
      font-family: $font-family-serif;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      transform: rotate(90deg) translate(-50%, -5%);
      transform-origin: center;
    }
  }
}