.rwd002-search-box {
  margin-bottom: 4em;
  @include media-down(lg) {
    width: 100%;
    margin-bottom: 2.5em;
  }
  &__inner {
    position: relative;
    width: 100%;
    max-width: 1280px;
    z-index: 0;
    @include media-down(sm) {
      width: 100%;
    }
  }
  &__input {
    @extend .form-control;
    padding: 1em 3.75em 1em 1em;
    background-color: $gray-100;
    transition: $transition-base;
    border-width: 0;
    @include media-up(md) {
      padding: 1.125em 4.25em 1.125em 2em;
    }
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4.25em;
    padding: 0 1.25em;
    transition: $transition-base;
    z-index: 1;
    @include media-down(sm) {
      width: 3.75em;
      padding: 0 1em;
    }
    > svg {
      width: 1.5625em;
      height: 100%;
      fill: $primary;
      @include media-down(sm) {
        width: 1em;
      }
    }
  }
  &__input:focus + &__btn {
    pointer-events: auto;
  }
}

.rwd002-search {
  width: 100%;
  max-width: 1334px;
  margin: 0 auto;
  padding: 0 1.6875rem;
  @include media-down(lg) {
    width: 100%;
  }

  &__lead {
    margin-bottom: 2em;
    padding-bottom: 1.5625rem;
    border-bottom: 1px solid #E1E1E1;
    text-align: right;
    font-feature-settings: "palt";
    font-size: 1.5625rem;
    letter-spacing: .16em;
    line-height: 1.5;
    @include media-down(sm) {
      margin-bottom: 1.75em;
      padding-bottom: 1.2rem;
      font-size: 1.375rem;
      text-align: left;
    }
    b{
      font-weight: $font-weight-bold;
    }
    .is-num {
      display: inline-block;
      font-family: 'Lato', $font-family-sans-serif;
      margin-left: 0.5em;
      @include media-down(sm) {
        display: block;
        margin: 0;
        font-size: 1.125rem;
      }
    }
  }

  &__list {
    padding: 0;
    list-style: none;
  }
}



.rwd002-search-item {
  display: flex;
  color: $gray-800;
  & + & {
    margin-top: 4em;
    @include media-down(sm) {
      margin-top: 3em;
    }
  }
  &__number {
    min-width: 2em;
    padding-right: 0.9em;
    font-family: 'Lato', $font-family-sans-serif;
    font-size: 1.5625rem;
    font-weight: $font-weight-bold;
    line-height: 1.3;
    letter-spacing: .12em;
    @include media-down(sm) {
      padding-right: 0.75em;
      font-size: 1.375rem;
    }
  }
  &__subject {
    margin-bottom: 1.25rem;
    font-size: 1.5625rem;
    font-weight: $font-weight-bold;
    letter-spacing: .12em;
    color: inherit;
    @include media-down(sm) {
      font-size: 1.375rem;
    }
  }
  &__cate {
    display: block;
    color: #6F7579;
    margin: .5em 0 0;
    font-weight: $font-weight-normal;
    font-size: 1rem;
    letter-spacing: .1em;
    @include media-up(md) {
      display: inline-block;
      margin: 0 0 0 1em;
      font-size: 1.0625rem;
    }
  }
  &__link {
    display: block;
    max-width: 100%;
    color: inherit;
    word-break: break-all;
    strong {
      padding: .1em .2em;
      background: tint($primary, 70%);
      font-weight: $font-weight-normal;
    }
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__link:hover &__subject, &__link:focus &__subject,
  &__link:hover &__url, &__link:focus &__url {
    text-decoration: underline;
  }

  &__text {
    color: inherit;
    font-size: 1rem;
    letter-spacing: .1em;
    line-height: 1.8;
    @include media-down(sm) {
      font-size: 0.9375rem;
    }
  }

  &__url {
    color: $primary;
    font-size: 1rem;
    letter-spacing: .1em;
    @include media-down(sm) {
      font-size: 0.875rem;
    }
  }
}