.rwd002-breadcrumbs {
  position: relative;
  display: flex;
  margin-bottom: map-get($spacers, 2);
  padding-left: min(80px, 5.75vw);
  padding-right: min(80px, 5.75vw);
  @include media-down(lg) {
    display: none;
  }
  &__list {
    @extend .breadcrumb;
    justify-content: flex-end;
    width: 100%;
    margin: auto;
  }
  &__item {
    @extend .breadcrumb-item;
    @extend .text-truncate;
    padding-left: 0;
    margin-right: .75em;
    max-width: 33%;
    font-family: $font-family-serif;
    font-size: $font-size-xxs;
    letter-spacing: 0.1em;
    font-feature-settings: "palt";
    @include media-down(md) {
      max-width: 14em;
    }
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: '-';
      padding-right: 0.75em;
      font-size: $font-size-xxs;
    }
  }
}
