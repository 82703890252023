@import './_rwd002';

//fonts
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500&family=Shippori+Mincho&display=swap');

//pages
@import './pages/home';
@import './pages/concept';
@import './pages/profiles';
@import './pages/letter';
@import './pages/works';
@import './pages/details';
@import './pages/info';
