@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.rwd002-pagetop {
  position: absolute;
  bottom: 2px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 150px;
  background-color: $white;
  font-family: "Shippori Mincho", $font-family-serif;
  font-size: $font-size-xs;
  color: $body-color;
  letter-spacing: 0.1em;
  text-decoration: none;
  transform-origin: bottom;
  
  @include media-down(lg) {
    display: none;
  }
  
  span {
    writing-mode: vertical-rl;
  }
  &:before {
    content: "";
    display: block;
    margin-bottom: 1.75em;
    width: 10px;
    height: 43px;
    background: svgUrlFunc(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 43.03"><polygon points="5 0 0 5 .66 5.66 4.53 1.79 4.53 43.03 5.47 43.03 5.47 1.79 9.34 5.66 10 5 5 0"/></svg>'
      )
      no-repeat center / contain;
    @include media-down(lg) {
      width: 7px;
      height: 30px;
      margin-bottom: 1.2em;
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 0;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer {

  &__row {
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    font-family: $font-family-serif;
    @include media-up(lg) {
      flex-direction: row-reverse;
      min-height: 460px;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__contact {
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    background-color: $body-color;
    color: $white;
    transition: $transition-base;
    @include media-down(lg) {
      min-height: 375px;
      padding: 102px 10px 124px;
    }
    .icon {
      margin-bottom: 40px;
      fill: $white;
      transition: $transition-base;
    }
    .is-title {
      font-family: 'Cormorant Garamond', $font-family-serif;
      font-size: calc(28 / 16 * $font-size-base);
      font-weight: $font-weight-medium;
      line-height: 1;
      letter-spacing: .15em;
      text-indent: 0.15em;
    }
    .is-text {
      display: block;
      margin-top: 36px;
      font-size: $font-size-sm;
      line-height: 1;
      letter-spacing: .06em;
      text-indent: 0.06em;
    }

    &:hover, &:focus {
      background-color: $primary;
      color: $gray-300;
      text-decoration: none;
      .icon {
        fill: #5EB8B5;
      }
    }
  }

  &__main {
    padding: 65px min(32px, 8.8vw) 63px;
    @include media-up(lg) {
      flex-basis: 60%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 100px;
      padding: 100px 2vw 0;
    }
  }

  &__main-inner {
    max-width: 100%;
    width: 555px;
    margin: 0 auto;
  }

  &__info {
    @include media-up(lg) {
      display: flex;
      flex-wrap: wrap;
    }
    @include media-down(lg) {
      margin-bottom: 34px;
      text-align: center;
    }
  }

  &__logo {
    margin: 0;
    @include media-down(lg) {
      margin: 0 0 21px;
    }
    figcaption {
      font-size: calc(13 / 16 * $font-size-base);
      letter-spacing: .1em;
      @include media-down(lg) {
        margin-bottom: .75em;
      }
    }
    .logo {
      margin: 0;
    }
  }
  &__btnwrap {
    @include media-up(lg) {
      order: 2;
      margin-left: auto;
    }
    @include media-down(lg) {
      margin-top: 1.8125rem;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 265px;
      height: 100%;
      letter-spacing: .06em;
      @include media-down(lg) {
        width: 212px;
        height: 50px;
        margin: 0 auto;
        font-size: calc(11 / 16 * $font-size-base);
        text-indent: 0.06em;
      }
      .icon {
        margin-right: 12px;
        fill: $white;
      }
    }
  }

  &__address {
    font-size: $font-size-xs;
    color: $body-color;
    line-height: 1.75;
    letter-spacing: 0.15em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      order: 3;
      width: 100%;
      margin: 20px 0 0;
      font-size: calc(13 / 16 * $font-size-base);
    }
    @include media-down(lg) {
      text-indent: 0.15em;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .addr2 {
      display: none;
    }
  }

  &__sns {
    margin-top: 20px;
    @include media-down(lg) {
      margin-top: 50px;
      text-align: center;
    }
    .btn-link {
      @include hoverOpacity(.6);
      font-size: calc(23 / 16 * $font-size-base);
      color: #373737;
    } 
  }


  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: $body-color;
    font-feature-settings: "palt";
    @include media-up(lg) {
      text-align: left;
    }
  }

  &__copyright {
    margin-top: 26px;
    @include media-down(md) {
      margin-top: 38px;
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 23px;
    @include media-down(lg) {
      margin-top: 32px;
    }
  }
}

.rwd002-footerNav {
  font-family: 'Cormorant Garamond', $font-family-serif;
  @include media-up(lg) {
    flex-basis: 60%;
    margin-top: 32px;
  }
  @include media-down(md) {
    display: block;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list {
    font-size: $font-size-base * 0.9375;
    color: $body-color;
    @include media-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
    @include media-down(md) {
      flex-grow: 1;
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
  &__sublist {
    @include media-down(md) {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1.5625rem;
      font-size: calc(13 / 16 * $font-size-base);
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  @include media-up(md) {
    padding-right: 1.5625rem;
  }
  @include media-down(md) {
    position: relative;
    border: solid rgba(#BEBEBE, 50%);
    border-width: 1px 0;
    & + & {
      border-top-width: 0;
    }
  }
  &__link {
    @include hoverOpacity(.65);
    position: relative;
    display: inline-block;
    padding: 0.3em 0;
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-medium;
    color: $body-color;
    letter-spacing: 0.145em;
    font-feature-settings: "palt";
    &:hover,
    &:focus {
      color: $body-color;
    }
    @include media-down(md) {
      flex-grow: 1;
      flex-basis: calc(100% - 40px);
      padding: .7em 40px .7em 1.5625rem;
    }
  }
}

.rwd002-footerNav-subitem {
  &:not(:last-child) {
    @include media-up(md) {
      padding-right: 1em;
    }
  }
  
  &__link {
    @include hoverOpacity(.65);
    display: inline-block;
    margin: 0;
    padding: 0.3em 0;
    letter-spacing: 0.16em;
    font-weight: $font-weight-medium;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $body-color;
      text-decoration: none;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}
