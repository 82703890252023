.is--letter {
  .rwd002-container {
    margin-top: 0;
  }
  &.is--list {
    .rwd002-main > .rwd002-list-content {
      opacity: 0;
    }
    .rwd002-pagination {
      margin-bottom: 75px;
      @include media-up(lg) {
        margin-bottom: 135px;
      }
    }
  }

  &.is--detail {
    .rwd002-moduletitle {
      display: none;
    }
    .rwd002-taglist.is-foot {
      @include media-up(lg) {
        margin-top: 0;
        margin-bottom: 170px;
      }
    }
    .rwd002-related-content {
      @include media-down(lg) {
        margin-bottom: 50px;
      }
    }
  }
}



.letter-list {
  margin-bottom: 135px;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg) {
    padding: 0 11.6%;
  }

  &__inner{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    @include media-up(lg) {
      width: 960px;
    }
  }

  &__list {
    margin: 0 auto;
    @include make-row();
    --#{$variable-prefix}gutter-y: 38px;
    @include media-up(lg) {
      --#{$variable-prefix}gutter-x: 48px;
      --#{$variable-prefix}gutter-y: 70px;
    }
  }
}

.letter-list-item {
  @include c-letter-item();

  @include make-col-ready();
  @include make-col(12);
  @include media-up(lg) {
    @include make-col(4);
  }
}


.letter-detail-head {
  @include c-detail-head();
  margin-bottom: 44px;
  @include media-up(lg) {
    margin-bottom: 60px;
  }
  @include media-down(lg) {
    padding-left: min(36px,9.6%);
    padding-right: min(36px,9.6%);
  }

  &__keywords {
  }
}

.letter-detail {
  margin-top: 30px;
  @include media-up(lg) {
    margin-top: 44px;
  }
  @include media-down(lg) {
    padding-left: 0;
    padding-right: 0;
  }
  &__image {
    max-width: 100%;
    width: 960px;
    margin: 0 auto 46px;
    @include media-up(lg) {
      margin: 0 auto 90px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__body {
    max-width: 100%;
    width: 720px;
    font-family: $font-family-serif;
    margin: 0 auto;
    @include media-down(lg) {
      padding-left: min(36px,9.6%);
      padding-right: min(36px,9.6%);
    }
    
    h2 {
      margin-bottom: 36px;
      font-family: inherit;
      font-size: $font-size-lg;
      @include media-up(lg) {
        margin-bottom: 40px;
        font-size: calc(22 / 16 * $font-size-base);
      }
    }

    .editor-template {
      font-size: calc(13 / 16 * $font-size-base) !important;
      letter-spacing: .05em;
      @include media-up(lg) {
        font-size: $font-size-sm !important;
      }
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .editor-template + .editor-template {
      margin-top: 32px;
      @include media-up(lg) {
        margin-top: 48px;
      }
    }

    p {
      margin: 0;
      font-size: calc(13 / 16 * $font-size-base);
      line-height: 1.86;
      letter-spacing: .05em;
      @include media-up(lg) {
        font-size: $font-size-sm;
      }
    }

    p + p {
      margin-top: 2rem;
    }
  }
}

// 関連記事
.letter-related {
  &__list {
    @include make-row(48px);
    justify-content: center;
  }
}
.letter-related-item {
  @include c-letter-item();
  @include make-col-ready();
  @include make-col(12);
  --bs-gutter-y: 30px;
  @include media-up(lg) {
    @include make-col(4);
  }

  .date {
    font-size: $font-size-xs;
  }
}