/*トップ*/

//CONCEPT
.block-concept {
  margin: 5.625rem auto;
  font-family: $font-family-serif;
  @include media-up(lg) {
    width: 80%;
    margin: 0 0 0 auto;
    padding: 0;
  }

  &__inner {
    @include media-up(lg) {
      margin-top: 13.125rem;
      margin-bottom: 3rem;
    }
    @include media-down(lg) {
      max-width: 100%;
      width: 290px;
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 2.625rem;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: .2em;
    @include media-up(lg) {
      margin-bottom: 3.375rem;
      font-size: calc(33 / 16 * $font-size-base);
    }
  }

  &__text {
    line-height: 2.5;
    letter-spacing: .05em;
    @include media-down(lg) {
      font-size: $font-size-sm;
    }
  }

  &__btnwrap {
    margin-top: 50px;
    @include media-up(lg) {
      margin-top: 3.375rem;
    }
  }
}

//PROFILE
.block-profile {
  max-width: 100%;
  width: 1140px;
  margin: 0 auto 160px;
  font-family: $font-family-serif;
  @include media-up(lg) {
    margin: 0 auto;
    padding: 0 20px;
  }
  &__inner {
    max-width: 100%;
    width: 560px;
    margin-left: auto;
    @include media-down(lg) {
      margin-right: auto;
    }
  }
  &__row {
    display: flex;
    @include media-down(lg) {
      margin-bottom: 48px;
    }
  }
  &__body {
    position: relative;
    bottom: -48px;
    flex-basis: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding: 10px 10px 24px min(29px, 7.7333vw);
    background-color: $gray-700;
    color: $white;
    z-index: 0;
    @include media-up(lg) {
      bottom: -70px;
      padding: 37px 42px;
    }
    .is-title {
      font-size: $font-size-xxs;
      letter-spacing: .2em;
      @include media-up(lg) {
        font-size: $font-size-sm;
      }
    }
    .is-name {
      strong {
        display: block;
        font-weight: $font-weight-normal;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: .2em;
        @include media-up(lg) {
          font-size: calc(20 / 16 * $font-size-base);
        }
      }
      small {
        display: block;
        font-size: $font-size-xxs;
        letter-spacing: .15em;
        @include media-up(lg) {
          font-size: $font-size-xs;
        }
      }
    }
  }
  &__bg {
    position: absolute;
    top: 43px;
    right: 4px;
    margin: 0;
    mix-blend-mode: multiply;
    z-index: -1;
    @include media-down(lg) {
      top: 28px;
      right: 3px;
      width: min(140px, 37.33333vw);
      height: auto;
    }
    img {
      @include media-down(lg) {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__img {
    flex-basis: 50%;
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__btnwrap {
    text-align: center;
    @include media-up(lg) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 70px;
      text-align: right;
    }
    @include media-down(lg) {
      padding-top: 50px;
    }
  }
}

// WORKS
.block-works {
  position: relative;
  margin: 160px auto 100px;
  padding-top: 110px;
  padding-bottom: map-get($spacers, 3);
  @include media-down(lg) {
    margin: 156px auto 86px;
    padding-top: 77px;
    background-color: #ECEFF1;
  }
  &:before {
    @include media-up(lg) {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: calc(50% + 420px);
      height: 520px;
      background-color: #ECEFF1;
      z-index: -1;
    }
  }

  &__title {
    @include c-block-title();
    max-width: 100%;
    width: 960px;
    margin: 0 auto 88px;
    @include media-up(lg) {
      margin-bottom: 106px;
    }
    @include media-down(lg) {
      text-indent: 0.15em;
      text-align: center;
    }
  }

  &__body {
    position: relative;
  }

  &__slider {
    @include media-up(lg) {
      padding: 0;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: calc(100% + 25px);
    right: auto;
    width: 45px;
    height: auto;
    transition: transform .3s ease-in-out;
    z-index: 1;
    @include media-up(lg) {
      bottom: calc(100% + 45px);
      width: 60px;
    }
    &:last-child {
      margin: 0;
    }
    &:after {
      content: "";
      width: 23px;
      height: 13px;
      background: center / contain no-repeat;
      transition: $transition-base;
    }
  }

  .swiper-button-next {
    left: 50%;
    @include media-up(lg) {
      left: calc(50vw + 420px);
    }
    &:hover:after,
    &:focus:after {
      background-image: svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12.67"><rect fill="#{$body-color}" x="0" y="5.84" width="21.93" height="1"/><polygon fill="#{$body-color}" points="16.67 12.67 15.96 11.96 21.59 6.33 15.96 .7 16.67 0 23 6.33 16.67 12.67"/></svg>'
      );
    }
  }
  .swiper-button-prev {
    left: calc(50% - 45px);
    @include media-up(lg) {
      left: calc(50vw + 360px);
    }
    &:hover:after,
    &:focus:after {
      background-image: svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12.67"><rect fill="#{$body-color}" x="1.07" y="5.84" width="21.93" height="1"/><polygon fill="#{$body-color}" points="6.33 12.67 0 6.33 6.33 0 7.04 .7 1.41 6.33 7.04 11.96 6.33 12.67"/></svg>'
      );
    }
  }
  .swiper-button-next:after {
    background-image: svgUrlFunc(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12.67"><rect fill="#{#7E7E7E}" x="0" y="5.84" width="21.93" height="1"/><polygon fill="#{#7E7E7E}" points="16.67 12.67 15.96 11.96 21.59 6.33 15.96 .7 16.67 0 23 6.33 16.67 12.67"/></svg>'
    );
  }
  .swiper-button-prev:after {
    background-image: svgUrlFunc(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12.67"><rect fill="#{#7E7E7E}" x="1.07" y="5.84" width="21.93" height="1"/><polygon fill="#{#7E7E7E}" points="6.33 12.67 0 6.33 6.33 0 7.04 .7 1.41 6.33 7.04 11.96 6.33 12.67"/></svg>'
    );
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: map-get($spacers, 4) 0;
    padding: 0 map-get($spacers, 3);
    @include media-up(lg) {
      max-width: 100%;
      width: 960px;
      margin: 54px auto 0;
    }
    .btn {
      @include media-up(lg) {
        margin-left: auto;
      }
    }
  }
}

.block-works-item {
  position: relative;
  padding: 0 40px;
  font-family: $font-family-serif;
  @include media-up(lg) {
    width: calc(590px + 80px);
    height: auto;
  }
  @include media-down(lg) {
    padding: 0;
  }
  &:hover,&:focus{
    text-decoration: none;
  }
  &:hover &__img, &:focus &__img{
    transform: scale(1.1);
    filter: grayscale(0);
  }
  &__thumb{
    margin-bottom: 1em;
    overflow: hidden;
  }
  &__img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 590/340;
    object-fit: cover;
    filter: grayscale(1);
    transition: transform .4s ease-in-out, filter .4s ease-in-out;
  }
  &__detail {
    text-align: center;
    .title {
      display: block;
      margin-bottom: 0.5em;
      font-size: $font-size-base;
      letter-spacing: 0.05em;
      text-indent: 0.05em;
    }
  }
}

// LETTER
.block-letter {
  margin: 85px auto 80px;
  @include media-up(lg) {
    margin: 180px auto;
  }

  &__inner {
    @include media-up(lg) {
      padding: 0 20px;
    }
  }

  &__title {
    @include c-block-title();
    max-width: 100%;
    width: 960px;
    margin: 0 auto 26px;
    text-indent: 0.15em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 56px;
    }
  }

  &__lead {
    font-family: $font-family-serif;
    letter-spacing: .1em;
    text-indent: 0.1em;
    text-align: center;
    @include media-down(lg) {
      font-size: $font-size-sm;
    }
  }

  &__body {
    max-width: 100%;
    width: 76.8%;
    margin: 36px auto 0;
    @include media-up(lg) {
      width: 960px;
      margin: 70px auto 0;
    }
  }

  &__list {
    @include make-row(48px);
    --bs-gutter-y: 36px;
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__btnwrap {
    max-width: 100%;
    width: 960px;
    margin: 50px auto 0;
    text-align: center;
    @include media-up(lg) {
      margin: 93px auto 0;
    }
  }
}

.block-letter-item {
  @include c-letter-item();

  @include make-col-ready();
  @include make-col(12);
  @include media-up(lg) {
    @include make-col(4);
  }
}

// DETAIL
.block-detail {
  @include media-up(lg) {
    margin: 187px auto 148px;
  }

  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $gray-700;
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    @include media-up(lg) {
      flex-shrink: 0;
      width: 20%;
      aspect-ratio: 1/1;
    }
    @include media-down(lg) {
      padding: 77px 20px 60px;
    }
  }

  &__title {
    @include c-block-title($white);
    margin: 0;
    @include media-down(lg) {
      text-align: center;
      text-indent: 0.15em;
    }
    small {
      display: block;
      margin-top: 12px;
      font-size: calc(13 / 16 * $font-size-base);
      letter-spacing: .03em;
      @include media-down(lg) {
        text-indent: 0.03em;
      }
    }
  }

  &__body {
    flex-grow: 1;
    @include media-up(lg) {
      width: 80%;
    }
  }

  &__list {
    @include make-row(0);
    --bs-gutter-y: 0;
    width: 100%;
    @include media-up(lg) {
      height: 100%;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__btnwrap {
    max-width: 100%;
    margin: 72px auto 0;
    padding: 0 28px;
    text-align: center;
    @include media-up(lg) {
      width: 960px;
      margin: 54px auto 0;
      text-align: right;
    }
  }
}

.block-detail-item {
  @include c-details-item();
  @include make-col-ready();
  @include make-col(12);

  @include media-up(lg) {
    @include make-col(3);
  }

  &__body {
    @include media-up(lg) {
      background-color: rgba($body-color, 60%);
    }
  }
}

// INFO
.block-info {
  margin: 164px auto 100px;
  @include media-up(lg) {
    margin: 148px auto 182px;
  }

  &__inner {
    margin-bottom: 100px;
    padding: 0 25px;
    @include media-up(lg) {
      margin-bottom: 160px;
      padding: 0 20px;
    }
  }

  &__title {
    @include c-block-title();
    max-width: 100%;
    width: 960px;
    margin: 0 auto 26px;
    text-indent: 0.15em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 56px;
    }
  }

  &__lead {
    font-family: $font-family-serif;
    letter-spacing: .1em;
    text-indent: 0.1em;
    text-align: center;
    @include media-down(lg) {
      font-size: $font-size-sm;
    }
  }

  &__body {
    max-width: 100%;
    width: 500px;
    margin: 67px auto 0;
    @include media-up(lg) {
      width: 960px;
      margin: 110px auto 0;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    @include media-up(lg) {
      width: calc(100% + 50px);
      margin: 0 -25px;
      gap: 57px 0;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__btnwrap {
    max-width: 100%;
    width: 960px;
    margin: 50px auto 0;
    padding: 0 32px;
    text-align: center;
    @include media-up(lg) {
      margin: 93px auto 0;
    }
  }

  &__recruit {
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    text-align: center;
    @include media-down(lg) {
      padding: 0 30px;
    }
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 300px;
      height: 64px;
      font-family: 'Cormorant Garamond', $font-family-serif;
      letter-spacing: .15em;
      text-indent: 0.15em;
      @include media-up(lg) {
        width: 400px;
        height: 80px;
        font-size: calc(23 / 16 * $font-size-base);
      }
    }
  }
}

.block-info-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-family: $font-family-serif;
  @include media-up(lg) {
    width: calc(100% / 2);
    padding: 0 25px;
  }

  & + & {
    @include media-down(lg) {
      margin-top: 36px;
    }  
  }

  &:hover, &:focus {
    text-decoration: none;
    .title {
      opacity: .7;
    }
    img {
      transform: scale(1.1);
    }
  }

  &__thumb {
    flex-shrink: 0;
    aspect-ratio: 1/1;
    width: 110px;
    overflow: hidden;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: $transition-base;
    }
  }

  &__body {
    padding: 12px 0 0 20px;
    @include media-down(lg) {
      flex-grow: 1;
      padding: 0 0 0 24px;
    }
  }

  .date-cate {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 3px;
    font-size: calc(13 / 16 * $font-size-base);
    line-height: 2;
    letter-spacing: .1em;
    .date, .cate {
      display: inline-block;
    }

    .date {
      margin-right: 16px;
      color: $gray-700;
    }
  
    .category {
      @extend .text-truncate;
      margin-right: 16px;
    }

    &.is-new:after {
      @extend .text-truncate;
      content: 'NEW';
      display: inline-block;
      font-family: $font-family-sans-serif;
      padding: 0 .75em;
      background-color: $body-color;
      font-size: calc(11 / 16 * $font-size-base);
      color: $white;
      letter-spacing: .1em;
      text-indent: 0.1em;
      height: 20px;
      line-height: 20px;
    }
  }

  .title {
    display: block;
    margin-top: 10px;
    font-size: $font-size-sm;
    line-height: 1.58;
    letter-spacing: .06em;
    transition: $transition-base;
    @include media-up(lg) {
      margin-top: 15px;
      letter-spacing: .1em;
    }
  }
  
  .tags {
    display: inline-flex;
    flex-wrap: wrap;
    gap: .2em .8125em;
    @include media-down(lg) {
      gap: .2em .5em;
    }
  }
  .tag {
    font-size: calc(13 / 16 * $font-size-base);
    color: $gray-500;
    letter-spacing: .1em;
  }
}