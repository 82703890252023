.rwd002-sitemap {
  width: 100%;
  max-width: 1020px;
  margin: map-get($spacers, 5) auto map-get($spacers, 6);
  padding: 0 1.875rem;
  font-family: $font-family-serif;
  line-height: 1.9;
  letter-spacing: .18em;
  @include media-up(md) {
    margin-top: map-get($spacers, 6);
  }
  @include media-down(sm) {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .is-main & {
    margin-top: 0;
    @include media-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media-down(xl) {
      margin-top: map-get($spacers, 5);
    }
  }

  &__contents {
    position: relative;
    padding: 2.25em 0 3em;
    border: solid #E9E9E9;
    border-width: 1px 0 0;
    @include media-up(md) {
      padding: 3.25em 1.875rem 4em;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      display: block;
      width: 304px;
      height: 1px;
      background: #C0C1C1;
      @include media-down(sm) {
        width: 152px;
      }
    }

    &-title {
      margin-bottom: 1em;
      font-weight: $font-weight-bold;
      font-size: 1.375rem;
      letter-spacing: .18em;
      line-height: 1.4;
      @include media-up(md) {
        margin-bottom: 1.5em;
        font-size: 1.5626rem;
      }
    }
  }

  &-category {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      margin: 0;
      @include media-up(md) {
        display: flex;
      }
    }
    & + & {
      margin-top: 3.25em;
      @include media-down(sm) {
        margin-top: 2em;
      }
    }
    &__title {
      position: relative;
      padding-left: 1em;
      font-weight: $font-weight-bold;
      font-size: 1rem;
      line-height: 1.8;
      letter-spacing: .18em;
      @include media-up(md) {
        flex-shrink: 0;
        width: 13em;
        font-size: $font-size-lg;
        padding-right: 2em;
      }
      &:before {
        content: '';
        position: absolute;
        top: .4em;
        left: 0;
        display: block;
        width: 9px;
        height: 16px;
        background: svgUrlFunc(
          '<svg height="16.03" viewBox="0 0 9.07 16.03" width="9.07" xmlns="http://www.w3.org/2000/svg"><path d="m353 758.76 8 7.66-8 7.65" fill="none" stroke="#373737" transform="translate(-352.69 -758.4)"/></svg>'
        )
        no-repeat center / contain;
      }
    }
  }

  &-category__title + &-article {
    padding-left: 1.1em;
  } 

  &-article {
    list-style: none;
    padding: 0;
    &__item {
      color: #838484;
      font-size: 0.875rem;
    }
    &__item + &__item {
      margin-top: 0.75em;
      @include media-down(sm) {
        margin-top: 0.5em;
      }
    }
  }
}