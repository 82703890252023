.is--info {
  .rwd002-container {
    margin-top: 0;
  }
  .rwd002-list-content {
    &__inner {
      max-width: 960px;
    }
    &__body {
      .cate {
        border-radius: 0;
      }
      .date {
        margin-bottom: 0;
      }
    }
  }

  .rwd002-block-content.is-catlist {
    @include media-up(lg) {
      margin-top: 64px;
    }
  }
}