.rwd002-moduletitle {
  margin-top: 26px;
  margin-bottom: 28px;
  text-align: center;
  @include media-up(lg) {
    margin-top: 50px;
    margin-bottom: 45px;
  }
  &__inner {
    @include c-module-title;
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }
}