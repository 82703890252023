.rwd002-profile {
  a {
    color: $primary;
    text-decoration: underline;
  }
  &__title {
    max-width: 100%;
    width: 1000px;
    margin: 0 auto;
    padding: 0 1.25rem;
    @include media-down(sm) {
      padding: 0 1.6875rem;
    }
  }
  &__entry {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1.25rem;
    @include media-down(sm) {
      padding: 0 1.6875rem;
    }
    .is-main &{
      @include media-up(xl) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    @include media-down(sm) {
      max-width: 100%;
    }
    th, td {
      padding: 1em;
      background: transparent;
      border: solid 1px #E9E9E9;
      border-width: 0 0 1px;
      font-size: 1em;
      line-height: 2;
      @include media-down(sm) {
        display: block;
        padding-left: 0;
        padding-right: 0;
      }
      address {
        margin-bottom: 0;
      }
      iframe {
        max-width: 100%;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        & + p {
          margin-top: 1em;
        }
      }
    }

    th {
      vertical-align: top;
      border-color: #BFC0C0;
      font-weight: $font-weight-bold;
      letter-spacing: .1em;
      white-space: nowrap;
      @include media-up(md) {
        width: 150px;
      }
      @include media-down(sm) {
        padding-bottom: 0;
        border: none;
      }
    }
    td {
      letter-spacing: .16em;
      @include media-up(md) {
        padding-left: 2em;
      }
      @include media-down(sm) {
        padding-top: .5em;
        padding-bottom: 0.9375rem;
      }
    }

    //写真のthはスマホ表示で削除
    .is-prof12 {
      th {
        @include media-down(sm) {
          display: none;
        }
      }
      td {
        @include media-down(sm) {
          padding-top: 1em;
        }
      }
    }
  }

  &__img {
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
    .is-caption {
      margin-top: 0.5em;
      font-size: 0.875rem;
    }
  }

  &__other {
    padding: 2em 1em 0;
    letter-spacing: .1em;
    @include media-down(md) {
      padding: 2em 0 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}