.is--details {
  .rwd002-container {
    margin-top: 0;
  }
  &.is--list {
    .rwd002-main > .rwd002-list-content {
      opacity: 0;
    }
    
    .rwd002-pagination {
      margin-bottom: 75px;
      @include media-up(lg) {
        margin-bottom: 135px;
        padding: 0 100px;
      }
      &__pageNav {
        @include media-up(lg) {
          max-width: 100%;
        }
      }
    }
  }
  &.is--detail {
    .rwd002-moduletitle {
      display: none;
    }
    .rwd002-taglist.is-foot {
      @include media-up(lg) {
        margin-top: 0;
        margin-bottom: 170px;
      }
    }
    .rwd002-related-content {
      @include media-down(lg) {
        margin-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.details-list {
  padding: 0 100px;
  @include media-up(lg) {
    margin-bottom: 124px;
  }
  @include media-down(lg) {
    padding: 0;
  }

  &__inner{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    @include media-up(lg) {
    }
  }

  &__list {
    @include make-row(0);
    margin: 0 auto;
  }
}
.details-list-item {
  @include c-details-item();

  @include make-col-ready();
  @include make-col(12);
  @include media-up(lg) {
    @include make-col(4);
  }

  &__body {
    align-items: flex-start;
    padding: 10% 20%;
    @include media-down(lg) {
      padding: 25px;
    }
    &:after {
      @include media-up(lg) {
        content: '';
        display: block;
        width: 43px;
        height: 10px;
        margin: 60px auto 0;
        background: center/contain no-repeat;
        background-image: svg-right-arrow($white);
        transition: $transition-base;
      }
    }
    .title {
      margin-bottom: 1.5rem;
      font-size: $font-size-base;
    }
  }
}


.details-detail-head {
  @include c-detail-head();
  display: flex;
  background: #ECEFF1;
  margin-bottom: 44px;
  @include media-up(lg) {
    margin-bottom: 60px;
  }
  @include media-down(lg) {
    flex-direction: column;
  }

  &__title {
    line-height: 1.8;
    letter-spacing: .1em;
  }

  &__body {
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: min(36px,9.6%);
    padding-right: min(36px,9.6%);
    @include media-up(lg) {
      flex-basis: 50%;
    }
  }
  
  &__body-inner {
    max-width: 100%;
    margin: 0;
    text-align: left;
    @include media-up(lg) {
      width: 320px;
    }
  }

  &__category {
    font-size: $font-size-base;
    letter-spacing: .1em;
  }

  &__keywords {
    width: 100%;
    padding: 0;
    &-list {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      text-align: left;
    }
    &-item {
      padding: 0;
      letter-spacing: .1em;
    }
  }

  &__image {
    aspect-ratio: 1/1;
    max-width: 100%;
    width: 100%;
    margin: 0;
    @include media-up(lg) {
      flex-basis: 50%;
    }
    img {
      width: 100%;
      height: auto;
      @include media-up(lg) {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__image-inner {
    width: 100%;
    height: 100%;
  }

  &__btnwrap {
    margin-top: 58px;
    @include media-up(lg) {
      margin-top: 44px;
    }
  }
}

.details-detail {
  margin-top: 30px;
  padding-left: 0;
  padding-right: 0;
  @include media-up(lg) {
    margin-top: 44px;
  }

  &__body {
    max-width: 100%;
    width: 800px;
    font-family: $font-family-serif;
    margin: 0 auto;
    @include media-down(lg) {
      padding-left: min(36px,9.6%);
      padding-right: min(36px,9.6%);
    }
    
    h2 {
      margin-bottom: 36px;
      font-family: inherit;
      font-size: $font-size-lg;
      @include media-up(lg) {
        margin-bottom: 40px;
        font-size: calc(22 / 16 * $font-size-base);
      }
    }

    .editor-template {
      font-size: calc(13 / 16 * $font-size-base) !important;
      letter-spacing: .05em;
      @include media-up(lg) {
        font-size: $font-size-sm !important;
      }
      .editor-template {
        font-size: calc(13 / 16 * $font-size-base) !important;
        letter-spacing: .05em;
        @include media-up(lg) {
          font-size: $font-size-sm !important;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }

    .editor-template + .editor-template {
      margin-top: 32px;
      @include media-up(lg) {
        margin-top: 48px;
      }
    }

    p {
      margin: 0;
      font-size: calc(13 / 16 * $font-size-base);
      line-height: 1.86;
      letter-spacing: .05em;
      @include media-up(lg) {
        font-size: $font-size-sm;
      }
    }

    p + p {
      margin-top: 2rem;
    }
  }
}

// 関連記事
.details-related {
  @extend .rwd002-related-content;
  padding-left: 0;
  padding-right: 0;
  &__inner {
    @extend .rwd002-related-content__inner;
    width: 100%;
  }
  &__list {
    width: 100%;
    @include make-row(0);
    --bs-gutter-y: 0;
    justify-content: center;
  }
  &__title {
    @extend .rwd002-related-content__title;
  }
}
.details-related-item {
  @include c-details-item();

  @include make-col-ready();
  @include make-col(12);
  @include media-up(lg) {
    @include make-col(3);
  }
  &__body {
    &:after {
      @include media-up(lg) {
        content: '';
        display: block;
        width: 43px;
        height: 10px;
        margin: 60px auto 0;
        background: center/contain no-repeat;
        background-image: svg-right-arrow($white);
        transition: $transition-base;
      }
    }
  }
}