@mixin c-detail-head() {
  text-align: center;
  
  &__title {
    @include c-block-title;
    display: block;
    margin-bottom: 10px;
    font-family: $font-family-serif;
    font-size: calc(20 / 16 * $font-size-base);

    @include media-up(lg) {
      font-size: calc(24 / 16 * $font-size-base);
    }
  }

  &__category {
    display: inline-block;
    font-family: $font-family-serif;
    line-height: 1.2;
    font-size: $font-size-sm;
    color: $body-color !important;
  }

  &__keywords {
    @extend .rwd002-taglist;
    margin-top: 33px;
    margin-bottom: 0;
    @include media-down(lg) {
      padding-left: 0;
      padding-right: 0;
    }
    &-tags {
      @extend .rwd002-taglist__tags;
    }
    &-list {
      @extend .rwd002-taglist__list;
      margin: 0;
      padding: 0;
    }
    &-item {
      @extend .rwd002-taglist__tagBtn;
    }
  }
}