.rwd002-taglist{
  margin-top: 3rem;
  margin-bottom: map-get($spacers, 6);
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  @include media-down(lg) {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }

  .is-main &{
    @include media-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  &__cats,
  &__tags {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    font-feature-settings: "palt";
  }

  &__cats {
    font-family: $font-family-serif;
    flex-wrap: wrap;
  }

  &__catBtn {
    @extend .btn;
    @extend .btn-link;
    position: relative;
    margin: .25em 0.8em 0;
    padding: .25em 1em;
    border-radius: 0;
    letter-spacing: .1em;
    text-indent: 0.1em;
    @include media-up(lg) {
      margin: .5em 0.8em 0;
      padding: 0.5em 1em;
    }
    @include media-down(lg) {
      font-size: calc(13 / 16 * $font-size-base);
    }
    &:hover, &:focus, &.current {
      text-decoration: none;
      &:after {
        transform: scale(1, 1);
      }
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 31px;
      height: 1px;
      margin: auto;
      background-color: $body-color;
      transform: scale(0, 1);
      transform-origin: center;
      transition: $transition-base;
      transition-duration: .2s;
    }
  }

  &__tags {
    flex-direction: column;
    margin-top: 1.25em;
    &:first-child {
      margin-top: 0;
    }
  }

  &__list {
    text-align: center;
  }

  &__btnwrap {
    margin-top: 0.4em;
    &:first-child{
      margin-top: 0;
    }
  }

  &__tagBtn {
    @extend .btn;
    @extend .btn-sm;
    font-weight: $font-weight-normal;
    font-size: calc(13 / 16 * $font-size-base);
    color: #AAA;
    letter-spacing: 0.14em;
    @include media-down(lg) {
      font-size: $font-size-xs;
    }
  }

  &__tglBtn {
    @extend .btn;
    @extend .btn-sm;
    &:focus{
      box-shadow: none;
    }
    font-size: $font-size-base * .8125;
  }
}