.rwd002-fixNav {
  position: fixed;
  transition: $transition-base;
  z-index: 2;
  
  @include media-up(lg) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: min( calc(100vh - 235px), 70vh );
    right: 0;
  }

  @include media-down(lg) {
    bottom: 0;
    display: flex;
    align-items: stretch;
    width: 100%;
    z-index: 11;
    transition: all 0.2s ease-in-out;
    transform: translateY(100%);
  }

  &.is-active {
    @include media-down(lg) {
      opacity: 1;
      display: flex;
      transform: translateY(0);
    }
  }

  &__inquiry {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: $white;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-base;
    letter-spacing: 0.1em;
    transition: $transition-base;

    @include media-up(lg) {
      position: absolute;
      right: 0;
      width: 64px;
      height: 171px;
      flex-direction: column;
    }

    @include media-down(lg) {
      width: 100%;
      padding: 1rem;
    }

    span {
      @include media-up(lg) {
        writing-mode: vertical-rl;
      }
    }

    &:hover,
    &:focus {
      background-color: darken($primary, 10%);
      color: $white;
      text-decoration: none;
    }
  }

  &__pagetop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $body-color;
    color: $white;
    font-family: "Roboto", $font-family-sans-serif;
    transition: $transition-base;
    letter-spacing: 0.1em;
    text-indent: 0.1em;

    @include media-up(lg) {
      position: absolute;
      right: 0;
      bottom: -171px;
      width: 64px;
      height: 64px;
      transform: translateY(100%);
    }

    @include media-down(lg) {
      min-width: 64px;
      font-size: $font-size-sm;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
      background-color: darken($body-color, 10%);
    }
  }
}